import React, { useRef, useState } from 'react'
import '../../style.css'
import './Navbar.css'
import digitaxlogo from '../../Images/Logo.png'
import phone from '../../Images/phonelogo.png'
import phonelogohover from '../../Images/phonologohover.svg'
import closebutton from '../../Images/Close.svg'
import checkimage from '../../Images/checkimage.svg'
import { Modal } from 'flowbite-react'
import CountrySelector from '../Country Selector/CountrySelector'
const Navbar = () => {
    const [hovered, setHovered] = useState(true);
    const [showModal, setshowModal] = useState(false)
    const [showThankyou, setshowThankyou] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [email, setEmail] = useState(true);
    const [otp, setOtp] = useState(false);
    const [verified, setVerified] = useState(false);

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const closeModal = () => {
        setshowModal(false)
    }
    const handleOpen = () => {
        setshowModal(true)
    }

    const handleHover = () => {
        setHovered(!hovered);
    };
    const closeThankyoumodal = () => {
        setshowThankyou(false)
    }

    const form = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        country: '',
        message: ''
    })

    const [errors, setErrors] = useState({})
    function showError(inputName, message) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: message,
        }));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        showError(name, '')
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            setshowModal(false);
            setshowThankyou(true);
            const config = {
                SecureToken: 'fbc47dfe-248b-433e-bc9c-e2106f9ed0b8',
                To: 'info@digitax.ai',
                From: 'info@digitax.ai',
                Subject: 'SCHEDULE',
                Body: `Name :${formData.name},
                       Email: ${formData.email},
                       Country: ${formData.country.value}
                       Phone: ${formData.phoneNumber}
                       Message: ${formData.message}`
            }
            window.Email.send(config)
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name) {
            errors.name = 'Name is required*'
        } else if (data.name.length < 3 || data.name.length > 25) {
            errors.name = 'Name should be between 3 and 25 characters'
        }

        if (!data.email) {
            errors.email = 'Email is required*'
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid Email Format'
        }

        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone Number is required*'
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Invalid Phone Number format'
        }

        if (!data.country) {
            errors.country = 'Select the Country*'
        }

        if (!data.message) {
            errors.message = 'Please fill out this field*'
        }

        return errors;
    }
    const handleEnterEmail = () => {
        setEmail(false);
        setOtp(true);
    }
    const handleEnterOtp = () => {
        setOtp(false);
        setVerified(true);
    }
    return (
        <>
            <nav class="navbar relative z-[99] bg-[#09393F]">
                <div class="flex flex-wrap items-center justify-between mx-auto p-4 w-full pt-[72px] px-[72px] navbar-outer">
                    <a href="/" class="flex items-center">
                        <img src={digitaxlogo} alt="Digitax Logo" />
                    </a>
                    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 bg-[#29B34E] dark:hover:bg-[#29B34E] dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" onClick={handleToggleMenu}>
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className={`${menuOpen ? "block" : "hidden"
                        } md:block md:w-auto absolute md:relative top-20 md:top-0  md:left-auto right-0 md:right-auto mt-4 md:p-0 items-center rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 z-[99999]`} id="navbar-default">
                        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 items-center rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 ">
                            <li>
                                <a href="/" class="block py-2 pl-3 pr-4 text-white rounded md:bg-transparent md:text-[#29B34E] md:p-0 dark:text-white" aria-current="page">Home</a>
                            </li>
                            <li>
                                <a href="#About" class="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#29B34E] md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About Us</a>
                            </li>
                            <li>
                                <a href="#Features" class="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#29B34E] md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Features</a>
                            </li>
                            <li>
                                <a href="#Filer" class="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#29B34E] md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Filer Now</a>
                            </li>
                            <li>
                                <a href="#Steps" class="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#29B34E] md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Steps</a>
                            </li>
                            <li>
                                <a href="#Reviews" class="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#29B34E] md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Reviews</a>
                            </li>
                            <li>
                            {/* <button className='flex items-center justify-center gap-[7px] w-[129px] h-[37px] navbar-button' onMouseEnter={handleHover} onMouseLeave={handleHover} onClick={()=>setDeleteOpen(true)}>Delete Account</button> */}
                            </li>
                            <button className='flex items-center justify-center gap-[7px] w-[129px] h-[37px] navbar-button' onMouseEnter={handleHover} onMouseLeave={handleHover} onClick={handleOpen}><img src={hovered ? phone : phonelogohover} alt="" />Contact Us</button>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* <Modal
                show={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                dismissible
                placement="center"
                className='relative z-[999] flex items-center justify-center left-[37%] top-[34%] max-w-fit w-full h-full mx-auto mt-[10%]'
            >
                <div style={{
                    background:"white",
                    padding:"10px",
                     width:"400px", 
                     borderRadius: '10px'
                }}>
                    {email&&<div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <input style={{borderWidth:'1px', borderColor:'black', width:'320px', borderRadius:'10px', padding:'10px'}}
                     placeholder="Enter Email"/>
                <button style={{fontWeight:'bold', fontSize:"16px"}} onClick={()=>handleEnterEmail()}>Submit</button>
                </div>
                </div>}
                    { otp &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
             <input style={{borderWidth:'1px', borderColor:'black', width:'200px', borderRadius:'10px', padding:'10px'}} placeholder="Enter OTP"/>
                <button style={{fontWeight:'bold', fontSize:"16px",marginRight:'20px' }} onClick={()=>handleEnterOtp()}>Submit OTP</button>
                </div>
}
{ verified &&
               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <button style={{ background: 'red', padding:'10px', borderRadius:'10px', color:'white', fontWeight:'bold', fontSize:'16px' }}>Delete Account</button>
             </div>
}
                </div>
            </Modal> */}

            <Modal
                show={showThankyou}
                placement="center"
                className='relative z-[999] flex items-center justify-center left-[37%] top-[34%] max-w-fit w-full h-full mx-auto mt-[10%]'
            >
                <div className='form-header flex self-end p-3 cursor-pointer' onClick={closeThankyoumodal}>
                    <img src={closebutton} alt="" />
                </div>

                <div className='flex flex-col items-center justify-center px-24 py-5 gap-5 thankyoumodal'>
                    <div>
                        <img src={checkimage} alt="" />
                    </div>
                    <div className='flex flex-col items-center justify-center gap-2 thankyoucontext'>
                        <h1>Thank you!</h1>
                        <p>Your submission has been received</p>
                    </div>
                    <div className='helpline'>
                        <p>Helpline num: <span className='text-[#29B34E]'>03327779013</span></p>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showModal}
                className='modal-main flex relative items-center justify-center z-[9999] w-full'
            >
                <div className="form-wrapper flex flex-col w-[466px] bg-white absolute z-[9999]" >
                    <div className='form-header flex self-end p-3 cursor-pointer' onClick={closeModal}>
                        <img src={closebutton} alt="" />
                    </div>
                    <div className='form-content px-7'>
                        <form className='flex flex-col gap-4' ref={form} onSubmit={handleSubmit}>
                            <div className='flex flex-col gap-2'>

                                <label htmlFor="Name">Name</label>
                                {errors.name && <span className='error'>{errors.name}</span>}
                                <input
                                    placeholder='Abvcd*'
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="Email">Contact Email</label>
                                {errors.email && <span className='error'>{errors.email}</span>}
                                <input
                                    placeholder='you@example.com'
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <label htmlFor="Name">Phone Number</label>
                            {errors.country && <span className='error'>{errors.country}</span>}
                            {errors.phoneNumber && <span className='error'>{errors.phoneNumber}</span>}
                            <div className='flex items-center gap-[15px]'>
                                <CountrySelector setData={setFormData} data={formData} />
                                <div className='flex flex-col gap-2'>
                                    <input
                                        className='phoneNumber-input w-[324px]'
                                        placeholder='XXXXXXXXX*'
                                        type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        inputMode='numeric'
                                        required
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="Name">Your Message</label>
                                {errors.message && <span className='error'>{errors.message}</span>}
                                <input
                                    placeholder='Type your message ....'
                                    type="text"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    className='message'
                                />
                            </div>
                            <div className="flex self-center pb-8">
                                <button className="form-send w-[107px] h-[31px] bg-[#29B34E] text-white "
                                    onClick={handleSubmit}
                                >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>


        </>
    )
}

export default Navbar