import React from 'react'
import './Steps.css'
import dashed from '../../Images/Steps/dashed.png'
import signup from '../../Images/Steps/Signup.svg'
import data from '../../Images/Steps/Data.svg'
import filer from '../../Images/Steps/Filer.svg'
const Steps = () => {
    return (
        <>
            <div className='steps-wrapper flex items-center justify-center px-[72px] w-full py-[149px]' id='Steps'>
                <div className='wrapper-left flex flex-col w-[50%] gap-[49px]'>
                    <h1>No One Does It
                        <br /> Better in <span className='text-[#29B34E]'>Pakistan!</span></h1>
                    <p>Our mission at Digitax is straightforward: to be the guiding torch for businesses navigating the foggy corridors of taxation. We're more than a service; we're your trusted tax ally.</p>
                    <p>Our mission at Digitax is straightforward: to be the guiding torch for businesses navigating the foggy corridors of taxation. We're more than a service; we're your trusted tax ally.</p>
                </div>
                <div className='wrapper-right flex flex-col w-[50%] gap-[62px]'>
                    <div className='signup-steps flex gap-[42px]'>
                        <div className='numbering h-[98px] flex items-center justify-center rounded-full relative'>
                            <img src={dashed} alt="" className='absolute' />
                            <div className='outer bg-[#29B34E] w-[82px] h-[82px] flex items-center justify-center rounded-full z-[1]' >
                                <div className='inner bg-[#0F393E] w-[65px] h-[65px] flex items-center justify-center rounded-full'>
                                    <p>1</p>
                                </div>
                            </div>
                        </div>
                        <div className='context'>
                            <div className='flex items-center gap-[11px] pb-[12px]'>
                                <div>
                                    <img src={signup} alt="" />
                                </div>
                                <div>
                                    <h1>Signup</h1>
                                </div>
                            </div>
                            <div>
                                <p>Embark on your clarity journey. A simple sign-up is the first step towards redefining your tax experience.</p>
                            </div>
                        </div>
                    </div>
                    <div className='givedata-steps flex gap-[42px]'>
                        <div className='numbering h-[98px] flex items-center justify-center rounded-full'>
                            <div className='outer bg-[#29B34E] w-[82px] h-[82px] flex items-center justify-center rounded-full z-[1]' >
                                <div className='inner bg-[#0F393E] w-[65px] h-[65px] flex items-center justify-center rounded-full'>
                                    <p>2</p>
                                </div>
                            </div>
                        </div>
                        <div className='context'>
                            <div className='flex items-center gap-[11px] pb-[12px]'>
                                <div>
                                    <img src={data} alt="" />
                                </div>
                                <div>
                                    <h1>Give Data</h1>
                                </div>
                            </div>
                            <div>
                                <p>We meticulously analyze and optimize it, ensuring your tax submissions are accurate, beneficial, and compliant.</p>
                            </div>
                        </div>
                    </div>
                    <div className='becomefiler-steps flex gap-[42px]'>
                        <div className='numbering h-[98px] flex items-center justify-center rounded-full'>
                            <div className='outer bg-[#29B34E] w-[82px] h-[82px] flex items-center justify-center rounded-full z-[1]' >
                                <div className='inner bg-[#0F393E] w-[65px] h-[65px] flex items-center justify-center rounded-full'>
                                    <p>3</p>
                                </div>
                            </div>
                        </div>
                        <div className='context'>
                            <div className='flex items-center gap-[11px] pb-[12px]'>
                                <div>
                                    <img src={filer} alt="" />
                                </div>
                                <div>
                                    <h1>Become Filer</h1>
                                </div>
                            </div>
                            <div>
                                <p>No more dread as tax season approaches. With our guidance, transform into a savvy filer, reaping benefits and avoiding pitfalls.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Steps