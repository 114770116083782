import React from 'react'
import './Choosing.css'
import mockupmobile from '../../Images/Choosing/mobileframe.png'
import appstore from '../../Images/Choosing/AppStore.png'
import playstore from '../../Images/Choosing/PlayStore.png'
import Slider from 'react-slick'
import digitaxvideo from '../../Images/Choosing/digitaxvideo.mp4'
import homepage from '../../Images/Choosing/homepage-mockup.png'
import challan from '../../Images/Choosing/challan-mockup.png'
const Choosing = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <div className="main-section w-full flex">
                <div className="section-context pl-[72px] pt-[11%] flex flex-col gap-[91px] w-[50%]">
                    <div className='upper-context'>
                        <h1>Choose <span className='text-[#29B34E]'>Simplicity</span> & <span className='text-[#29B34E]'>Savings,</span>
                            <br />Choose DigiTax!</h1>
                        <p>With Digitax, that’s not just a dream. Our holistic approach ensures you're not only compliant but confident, understanding every facet of your tax obligations.
                        </p>
                    </div>
                    <div className='download-web'>
                        <div className='download-tag'>
                            <p>Download our <span className='text-[#29B34E]'>Digitax app</span></p>
                        </div>
                        <div className='flex gap-[6px]'>
                            <img src={appstore} alt="" />
                            <img src={playstore} alt="" />
                        </div>
                    </div>
                </div>
                <div className="mockup relative">
                    <div className='mockup-inner'>
                        <Slider {...settings}>
                            <div className=''>
                                <img src={mockupmobile} alt="" />
                                <div className='video-context'>
                                    <h2>Welcome</h2>
                                    <video src={digitaxvideo} className='w-[223px] h-[180px]' autoPlay loop muted />
                                    <p>Filing taxes is as simple as a <br /><span className='text-[#04B74D]'>few taps.</span></p>
                                </div>
                            </div>
                            <div className=''>
                                <img src={homepage} alt="" />
                            </div>
                            <div className=''>
                                <img src={challan} alt="" />
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className='download-mobile'>
                    <div className='download-tag'>
                        <p>Download our <span className='text-[#29B34E]'>Digitax app</span></p>
                    </div>
                    <div className='flex gap-[6px]'>
                        <img src={appstore} alt="" />
                        <img src={playstore} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Choosing