import React, { useRef, useState } from 'react'
import './Features.css'
import Featurecomp from './Featurecomp.jsx'
import taxsavings from '../../Images/Features/tax-savings.svg'
import guidance from '../../Images/Features/guidance.svg'
import returnfiling from '../../Images/Features/return.svg'
import taxeducation from '../../Images/Features/tax-education.svg'
import taxprofile from '../../Images/Features/tax-profile.svg'
import taxsavingsimg from '../../Images/Features/tax-savings img.svg'
import guidanceimg from '../../Images/Features/guidanace img.svg'
import returnimg from '../../Images/Features/return img.svg'
import taxeducationimg from '../../Images/Features/tax-education img.svg'
import taxprofileimg from '../../Images/Features/tax-profile img.svg'
import Slider from 'react-slick'
const Features = () => {
    const sliderRef = useRef()
    const pointsData = [
        {
            imageSrc: taxsavings,
            title: 'Efficient Tax Savings',
            description: 'Streamline your tax commitments and benefit from optimized deduction rates, ensuring more of your hard-earned money stays with you.',
        },
        {
            imageSrc: guidance,
            title: 'Guidance for Salaried Individuals',
            description: 'Specifically tailored for those earning a regular salary, making sure that you\'re not overpaying and are aware of all possible deductions.',
        },
        {
            imageSrc: returnfiling,
            title: 'Seamless Return Filing',
            description: 'We help you understand, claim, and adjust your dues efficiently, ensuring maximum returns.',
        },
        {
            imageSrc: taxeducation,
            title: 'Tax Education & Insights',
            description: 'Digitax provides a wealth of information to help you navigate the tax world with confidence.',
        },
        {
            imageSrc: taxprofile,
            title: 'Complete Tax Profile Management',
            description: "Maintain an organized and updated tax profile, ensuring you're always ready for any financial decision or opportunity that comes your way."
        },
    ];
    const images = [
        {
            imageSrc: taxsavingsimg
        },
        {
            imageSrc: guidanceimg
        },
        {
            imageSrc: returnimg
        },
        {
            imageSrc: taxeducationimg
        },
        {
            imageSrc: taxprofileimg
        }
    ]
    const [selectedIdx, setSelectedIdx] = useState(0);
    const handleHover = (index) => {
        setSelectedIdx(index);
        sliderRef.current.slickGoTo(index);
    };

    const settings = {
        // dots: true,
        lazyload: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return (
        <>
            <div className='flex flex-col w-full items-center justify-center relative features-wrapper' id='Features'>
                <div className='features-content flex flex-col items-center justify-center pt-24 z-[2]'>
                    <h1 className='pb-[22px]'>Top-Tier <span className='text-[#29B34E]'>Services</span> With Unbeatable pricing</h1>
                    <p className='pb-20'>DigiTax combines industry-leading expertise with wallet-friendly rates, offering unparalleled tax services. Dive into a seamless experience where quality meets affordability, simplifying tax complexities for all.</p>
                </div>
                <div className='features-points flex justify-center relative z-[2] w-full mb-[96px] gap-[37px] '>
                    <div className='features-points-left flex flex-col gap-[9px] w-[25%] h-max '>
                        {pointsData.map((data, index) => (
                            <Featurecomp
                                key={index}
                                index={index}
                                imageSrc={data.imageSrc}
                                title={data.title}
                                description={data.description}
                                isSelected={index === selectedIdx}
                                onSelect={() => setSelectedIdx(index)}
                                onHover={handleHover}
                                
                            />
                        ))}
                    </div>
                    <div className='features-point-right '>
                        <img src={images[selectedIdx].imageSrc} alt="" />
                    </div>
                    <div className='features-point-right-mob'>
                        <Slider ref={sliderRef} {...settings}  >
                            {images.map((image, index) => (
                                <div key={index}>
                                    <img src={images[index].imageSrc} alt="" />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features