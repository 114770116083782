import React from 'react'
import CustomSlider from '../Components/CustomSlider/CustomSlider'
import About from '../Components/About/About'
import Features from '../Components/Features/Features'
import Footer from '../Components/Footer/Footer'
import Getintouch from '../Components/Getintouch/Getintouch'
import Steps from '../Components/Steps/Steps'
import Filer from '../Components/Filer/Filer'
import Choosing from '../Components/Choosing/Choosing'
const Home = () => {
  return (
    <>
        <CustomSlider/>

        <About/>

        <Features/>

        <Choosing/>

        <Filer/>

        <Steps/>

        <Getintouch/>
    </>
  )
}

export default Home