import React from 'react'
import aboutimg from '../../Images/About/AboutMockup.png'
import './About.css'
const About = () => {
    return (
        <>
            <div className='w-full flex items-start justify-between z-[1] relative aboutdigitax pb-[90px]' id='About'>
                <div className='pl-16 w-1/2 pt-48 aboutdigitax-left'>
                    <div className='flex-col items-start pb-[52px]'>
                        <h1>About <span className='text-[#29B34E]'>Digitax</span></h1>
                        <p>Taxes can be a daunting puzzle – endless forms, perplexing regulations, and ever-changing laws. Digitax was born out of the need to simplify this complexity. We provide clarity amidst confusion, making tax filing a breeze for individuals like you.</p>
                    </div>
                    <div className='flex-col items-start'>
                        <h1>Introduction to the <br />company & its mission.</h1>
                        <p>From ensuring you benefit from the lowest tax deductions to guiding you in reclaiming every penny owed to you, DigiTax shines a light on the perks of being a filer. We're not just here to help; we're here to transform your tax journey into an enjoyable story, packed with savings, empowerment, and a clear path to financial clarity.</p>
                    </div>
                </div>
                <div className='pr-12 pt-24 aboutdigitax-right'>
                    <img src={aboutimg} alt="about-mockup" />
                </div>
            </div>
        </>
    )
}

export default About