import React, { useState, useEffect, useRef } from 'react';
import './CustomSlider.css'
import SliderItem from '../SliderItem/SliderItem';
import overlay from '../../Images/overlay.png';
import closebutton from '../../Images/Close.svg'
import checkimage from '../../Images/checkimage.svg'
import { Modal } from 'flowbite-react'
import CountrySelector from '../Country Selector/CountrySelector'

const images = [
    './FirstImage.png',
    './SecondImage.png',
];

const Slider = () => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentImage((currentImage + 1) % images.length);
        }, 6000);

        return () => clearTimeout(timer);
    }, [currentImage]);

    const [showModal, setshowModal] = useState(false)
    const [showThankyou, setshowThankyou] = useState(false)

    const form = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        country: '',
        message: ''
    })

    const [errors, setErrors] = useState({})
    function showError(inputName, message) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: message,
        }));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        showError(name, '')
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            setshowModal(false);
            setshowThankyou(true);
            const config = {
                SecureToken: 'fbc47dfe-248b-433e-bc9c-e2106f9ed0b8',
                To: 'info@digitax.ai',
                From: 'info@digitax.ai',
                Subject: 'SCHEDULE',
                Body: `Name :${formData.name},
                       Email: ${formData.email},
                       Country: ${formData.country.value}
                       Phone: ${formData.phoneNumber}
                       Message: ${formData.message}`
            }
            window.Email.send(config)
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name) {
            errors.name = 'Name is required*'
        } else if (data.name.length < 3 || data.name.length > 25) {
            errors.name = 'Name should be between 3 and 25 characters'
        }

        if (!data.email) {
            errors.email = 'Email is required*'
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid Email Format'
        }

        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone Number is required*'
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Invalid Phone Number format'
        }

        if (!data.country) {
            errors.country = 'Select the Country*'
        }

        if (!data.message) {
            errors.message = 'Please fill out this field*'
        }

        return errors;
    }
    const closeModal = () => {
        setshowModal(false)
    }

    const handleOpen = () => {
        setshowModal(true)
    }
    const closeThankyoumodal = () => {
        setshowThankyou(false)
    }

    return (
        <>
            <div className="relative h-[800px] -mt-[2.5%] main-section-bg">
                {images.map((image, index) => (
                    <SliderItem
                        key={index}
                        image={image}
                        active={index === currentImage}
                    />
                ))}
                <div>
                    <img src={overlay} alt="" className='absolute top-[-1%] right-0 w-full h-[810px] z-[1] overlay' />
                </div>
            </div>

            {currentImage === 0 ? (
                <div className="absolute w-fit text-white z-[9] header">
                    <h2 className="header-heading">Zero <span className='text-[#29B34E]'>Pareshani</span>, Only <span className='text-[#29B34E]'>Asaani</span>!</h2>
                    <p className="header-tagline">Har Pakistani, Ab Banay Ga <b>Tax Filer!</b></p>
                    <button className="bg-[#29B34E] text-white px-6 py-2 rounded-lg hover:bg-white hover:text-[#0F393E] duration-500 box-shadow-custom-box-shadow mt-[38px]" onClick={handleOpen}>Become a Filer Now!</button>
                </div>
            ) : (
                <div className="absolute w-fit text-white z-[9] header">
                    <h2 className="header-heading">Apki Har Tax Problem <br />Ka <span className='text-[#29B34E]'>Quick</span> & <span className='text-[#29B34E]'>Easy</span> Solution!</h2>
                    <p className="header-tagline">Quick and <b>Stress-Free</b> Solutions for Every Pakistani!</p>
                    <button className="bg-[#29B34E] text-white px-6 py-2 rounded-lg hover:bg-white hover:text-[#0F393E] duration-500 box-shadow-custom-box-shadow mt-[38px]" onClick={handleOpen}>Become a Filer Now!</button>
                </div>
            )}

            <Modal
                show={showThankyou}
                placement="center"
                className='relative z-[999] flex items-center justify-center left-[37%] top-[34%] max-w-fit w-full h-full mx-auto mt-[10%]'
            >
                <div className='form-header flex self-end p-3 cursor-pointer' onClick={closeThankyoumodal}>
                    <img src={closebutton} alt="" />
                </div>

                <div className='flex flex-col items-center justify-center px-24 py-5 gap-5 thankyoumodal'>
                    <div>
                        <img src={checkimage} alt="" />
                    </div>
                    <div className='flex flex-col items-center justify-center gap-2 thankyoucontext'>
                        <h1>Thank you!</h1>
                        <p>Your submission has been received</p>
                    </div>
                    <div className='helpline'>
                        <p>Helpline num: <span className='text-[#29B34E]'>03327779013</span></p>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showModal}
                className='modal-main flex relative items-center justify-center z-[9999] w-full'
            >
                <div className="form-wrapper flex flex-col w-[466px] bg-white absolute z-[9999]" >
                    <div className='form-header flex self-end p-3 cursor-pointer' onClick={closeModal}>
                        <img src={closebutton} alt="" />
                    </div>
                    <div className='form-content px-7'>
                        <form className='flex flex-col gap-4' ref={form} onSubmit={handleSubmit}>
                            <div className='flex flex-col gap-2'>

                                <label htmlFor="Name">Name</label>
                                {errors.name && <span className='error'>{errors.name}</span>}
                                <input
                                    placeholder='Abvcd*'
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="Email">Contact Email</label>
                                {errors.email && <span className='error'>{errors.email}</span>}
                                <input
                                    placeholder='you@example.com'
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <label htmlFor="Name">Phone Number</label>
                            {errors.country && <span className='error'>{errors.country}</span>}
                            {errors.phoneNumber && <span className='error'>{errors.phoneNumber}</span>}
                            <div className='flex items-center gap-[15px]'>
                                <CountrySelector setData={setFormData} data={formData} />
                                <div className='flex flex-col gap-2'>
                                    <input
                                        className='phoneNumber-input w-[324px]'
                                        placeholder='XXXXXXXXX*'
                                        type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        inputMode='numeric'
                                        required
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="Name">Your Message</label>
                                {errors.message && <span className='error'>{errors.message}</span>}
                                <input
                                    placeholder='Type your message ....'
                                    type="text"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    className='message'
                                />
                            </div>
                            <div className="flex self-center pb-8">
                                <button className="form-send w-[107px] h-[31px] bg-[#29B34E] text-white "
                                    onClick={handleSubmit}
                                >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Slider;
