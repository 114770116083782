import React from 'react';

const SliderItem = ({ image, active }) => {
    return (
        <div
            className={`absolute w-full h-full transform transition-opacity duration-500 ${active ? 'opacity-100' : 'opacity-0'
                }`}
        >
            <img src={image} alt="Slider Item" className="w-full h-full" />
            
        </div>
    );
};

export default SliderItem;
