import React from 'react'
import './terms.css'
const Terms = () => {
  return (
    <>
      <div className="privacy-banner flex flex-col bg-[#09393F] text-white px-16">
        <div className="privacy-heading">
            <h1>Terms of Use</h1>
        </div>
        <h2>Terms & Conditions of <a href="/" className='text-[#29B34E]'>DIGITAX</a></h2>
        <p>Please thoroughly review the Terms of Service presented below, as they establish the legally binding terms and conditions that govern your engagement with this website (hereby referred to as "this Website" or "the Site"). <a href="/" className='text-[#29B34E]'>DIGITAX</a> may revise these Terms of Service periodically without prior notice. By accessing the Site subsequent to any such changes, you agree to be bound by the revised terms.</p>

        <h2>General Terms of Use</h2>
        <p>In exchange for utilizing the Site, you pledge to: (a) provide sincere, precise, current, and comprehensive details about yourself as prompted by the registration form, and (b) maintain the accuracy of this information to keep it sincere, precise, current, and complete. If any information you provide is found to be false, inaccurate, outdated, or incomplete, <a href="/" className='text-[#29B34E]'>DIGITAX</a> reserves the right to terminate your account and reject any and all current or future use of the Site. You agree not to sell or transfer your use of or access to the Site.</p>
        <p>By engaging with the <a href="/" className='text-[#29B34E]'>DIGITAX</a> website, which includes any applets, software, and content therein, you acknowledge that usage of the Site is at your sole risk. THE SITE IS PROVIDED "AS IS," WITHOUT ANY GUARANTEE OF ANY SORT, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF INFORMATION, DATA, SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS MADE AVAILABLE THROUGH OR IN CONNECTION WITH THE SITE. SPECIFICALLY, <a href="/" className='text-[#29B34E]'>DIGITAX</a> DISCLAIMS ANY WARRANTIES, INCLUDING, BUT NOT LIMITED TO: (1) ANY WARRANTIES REGARDING THE AVAILABILITY, ACCURACY, RELEVANCE, OR CONTENT OF INFORMATION, PRODUCTS OR SERVICES AND (2) ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THIS DISCLAIMER EXTENDS TO ANY DAMAGES OR INJURY DUE TO PERFORMANCE FAILURE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, OPERATIONAL OR TRANSMISSION DELAY, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR CONTRACT BREACH, HARMFUL CONDUCT, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.</p>
        <p>NEITHER <a href="/" className='text-[#29B34E]'>DIGITAX</a> NOR ITS EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES, WEBSITE CO-BRANDING PROVIDERS OR CONTENT OR SERVICE PROVIDERS WILL BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING FROM THE USE OF THE SERVICE, THE INABILITY TO ACCESS OR USE THE SERVICE, OR OUT OF ANY BREACH OF ANY WARRANTY.</p>
        <p><a href="/" className='text-[#29B34E]'>DIGITAX</a> claims ownership and/or the legal right to all trademarks, registered trademarks, and/or service marks displayed on this Site, and holds copyright or license to the content and/or information on this Website, including but not limited to any screens appearing on the Site. You are prohibited from downloading and/or saving a copy of any screens except as explicitly permitted by these Terms of Service, for any purpose. You may print a copy of the information on this Site for personal use or records. Unauthorized use of this Site may infringe copyright and other laws of Pakistan, potentially leading to legal penalties. <a href="/" className='text-[#29B34E]'>DIGITAX</a> does not grant any license or authorization to any user of its trademarks, registered trademarks, service marks, or copyrighted material or other intellectual property by placing them on this Website.</p>
        <p><a href="/" className='text-[#29B34E]'>DIGITAX</a> reserves the right to modify any information on this Website including but not limited to revising and/or deleting features or other information without prior notice. Accessing certain links within this Website might redirect you to other websites for which <a href="/" className='text-[#29B34E]'>DIGITAX</a> assumes no responsibility for the content, availability, or otherwise. The content presented at this Site may vary depending on your browser capabilities.</p>


        <h2>User Conduct</h2>
        <p>While using the Site, you are prohibited from:</p>
        <p>•	Inhibiting any other user from enjoying the Site.</p>
        <p>•	Posting or transmitting any illegal, fraudulent, defamatory, obscene, threatening, offensive, or otherwise objectionable content, including any actions that could be seen as criminal, invite liability, or otherwise breach any applicable laws.</p>
        <p>•	Engaging in activities like spamming, sending unsolicited commercial communications, or distributing viruses or other harmful components.</p>
        <p>•	Uploading, posting, publishing, or distributing any material without proper authorization from copyright holders.</p>
        <p>•	Using the Site to exploit any material for commercial gain not expressly allowed.</p>

        <h2>Communication</h2>
        <p>Unless specified otherwise, all communications that you send to this Website, such as emails, comments, or suggestions, will be considered non-confidential and nonproprietary. <a href="/" className='text-[#29B34E]'>DIGITAX</a> is free to use the content of your communications for any purpose without compensation to you.</p>

        <h2>Non-Compliance and Termination</h2>
        <p><a href="/" className='text-[#29B34E]'>DIGITAX</a> may terminate your access or account without notice if you violate these Terms of Service, infringe on <a href="/" className='text-[#29B34E]'>DIGITAX</a>'s rights, or engage in conduct deemed inappropriate for continued access.</p>

        <h2>Links to and from the Website</h2>
        <p><a href="/" className='text-[#29B34E]'>DIGITAX</a> takes no responsibility for the accuracy or availability of information provided by websites linked to this Website. Links to external websites do not constitute an endorsement by <a href="/" className='text-[#29B34E]'>DIGITAX</a> of the sponsors or the information and products presented on those websites.</p>

        <h2>Jurisdiction</h2>
        <p>This Agreement shall be governed by and construed in accordance with the laws of Pakistan, without giving effect to its conflict of law provisions. You agree that the appropriate court in Pakistan will have the exclusive jurisdiction to resolve all disputes arising under this Agreement and you consent to personal jurisdiction in such forum.</p>

        <h2>Miscellaneous</h2>
        <p>These Terms of Service constitute the entire agreement between you and <a href="/" className='text-[#29B34E]'>DIGITAX</a> and govern your use of the Site. Failure by <a href="/" className='text-[#29B34E]'>DIGITAX</a> to enforce any provision of these Terms of Service shall not be construed as a waiver of any provision or right. If any provision of these Terms of Service is found to be invalid by a court of competent jurisdiction, you nevertheless agree that the court should endeavor to give effect to the intentions as reflected in the provision, and the other provisions of these Terms of Service remain in full force and effect.</p>

        <p><a href="/" className='text-[#29B34E]'>DIGITAX</a>'s rights under these Terms of Service may not be waived unless <a href="/" className='text-[#29B34E]'>DIGITAX</a> acknowledges the waiver in writing.</p>

        <h2>Contact Information</h2>
        <p>Should you have any questions or need further clarification about these Terms of Service, please contact <a href="/" className='text-[#29B34E]'>DIGITAX</a> at <a href="mailto:info@digitax.ai">info@digitax.ai</a></p>

      </div>
    </>
  )
}

export default Terms
