import React, { useRef, useState } from "react";
import "./Footer.css";
import fb from "../../Images/Footer/Facebook.svg";
import twt from "../../Images/Footer/Twitter.svg";
import ig from "../../Images/Footer/Instagram.svg";
import lin from "../../Images/Footer/LinkedIn.svg";
import dash from "../../Images/Footer/dash.png";
import idrak from "../../Images/Footer/idrak-logo.png";
import seperator from "../../Images/Footer/seperator.png";
import ingenetic from "../../Images/Footer/ingenetic-logo.png";
import closebutton from "../../Images/Close.svg";
import checkimage from "../../Images/checkimage.svg";
import { Modal } from "flowbite-react";
import CountrySelector from "../Country Selector/CountrySelector";
const Footer = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showThankyou, setshowThankyou] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const closeModal = () => {
    setshowModal(false);
  };
  const handleOpen = () => {
    setshowModal(true);
  };

  const closeThankyoumodal = () => {
    setshowThankyou(false);
  };

  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    country: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  function showError(inputName, message) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: message,
    }));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    showError(name, "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setshowModal(false);
      setshowThankyou(true);
      const config = {
        SecureToken: "fbc47dfe-248b-433e-bc9c-e2106f9ed0b8",
        To: "info@digitax.ai",
        From: "info@digitax.ai",
        Subject: "SCHEDULE",
        Body: `Name :${formData.name},
                       Email: ${formData.email},
                       Country: ${formData.country.value}
                       Phone: ${formData.phoneNumber}
                       Message: ${formData.message}`,
      };
      window.Email.send(config);
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name) {
      errors.name = "Name is required*";
    } else if (data.name.length < 3 || data.name.length > 25) {
      errors.name = "Name should be between 3 and 25 characters";
    }

    if (!data.email) {
      errors.email = "Email is required*";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid Email Format";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required*";
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = "Invalid Phone Number format";
    }

    if (!data.country) {
      errors.country = "Select the Country*";
    }

    if (!data.message) {
      errors.message = "Please fill out this field*";
    }

    return errors;
  };

  return (
    <>
      <div className="footer-main flex flex-col items-start px-[72px] pt-[113px] pb-[10px]">
        <div className="content grid grid-cols-4 gap-[100px] pb-[30px]">
          <div className="flex flex-col w-fit web-section">
            <h1>Navigation Menu</h1>
            <p>Home</p>
            <p>
              <a href="#Features">Features</a>
            </p>
            <p>
              <a href="/requestAccountDeactivation">Delete Account</a>
            </p>

            <p>
              <a href="#Filer">Filer Now</a>
            </p>
          </div>
          <div
            className={`mobile-section flex flex-col w-fit ${
              isExpanded ? "expanded" : ""
            }`}
          >
            <h1 onClick={toggleExpand}>Navigation Menu</h1>
            {isExpanded && (
              <>
                <p>Home</p>
                <p>
                  <a href="#Features">Features</a>
                </p>
                <p>
                  <a href="#Filer">Filer Now</a>
                </p>
              </>
            )}
          </div>
          <div className="flex flex-col w-fit web-section">
            <h1>Company</h1>
            <p>
              <a href="#About">About Us</a>
            </p>
            <p>
              <a href="#Steps">Steps</a>
            </p>
            <p>
              <a href="#Reviews">Reviews</a>
            </p>
          </div>
          <div
            className={`mobile-section flex flex-col w-fit ${
              isExpanded ? "expanded" : ""
            }`}
          >
            <h1 onClick={toggleExpand}>Company</h1>
            {isExpanded && (
              <>
                <p>
                  <a href="#About">About Us</a>
                </p>
                <p>
                  <a href="#Steps">Steps</a>
                </p>
                <p>
                  <a href="#Reviews">Reviews</a>
                </p>
              </>
            )}
          </div>
          <div className="flex flex-col w-fit web-section">
            <h1>Support</h1>
            <p onClick={handleOpen} style={{ cursor: "pointer" }}>
              Contact Us
            </p>
            <p>
              <a href="/Privacy">Privacy Policy</a>
            </p>
            <p>
              <a href="/Terms">Terms & Conditions</a>
            </p>
          </div>
          <div
            className={`mobile-section flex flex-col w-fit ${
              isExpanded ? "expanded" : ""
            }`}
          >
            <h1 onClick={toggleExpand}>Support</h1>
            {isExpanded && (
              <>
                <p onClick={handleOpen} style={{ cursor: "pointer" }}>
                  Contact Us
                </p>
                <p>
                  <a href="/Privacy">Privacy Policy</a>
                </p>
                <p>
                  <a href="/Privacy">Terms & Conditions</a>
                </p>
              </>
            )}
          </div>
          <div className="flex flex-col">
            <h1>Submit Your Email</h1>
            {errors.email && <span className="error">{errors.email}</span>}
            <div className="signup flex items-center gap-[15px] justify-center mt-[20px]">
              <input
                type="text"
                placeholder="Your email"
                value={formData.email}
                onChange={handleChange}
                className="footer-input w-[265px] bg-[#EEF3F8] rounded-2xl h-[56px] pl-[20px] "
              />
              <button
                className="signup-button bg-[#0F393E] text-white w-[150px] h-[56px] rounded-2xl"
                onClick={handleSubmit}
              >
                Send
              </button>
            </div>
            <div className="social-icons flex items-center justify-start gap-[15.2px] pt-[28px]">
              <div className="icons w-[30px] h-[30px] bg-[#0F393E] rounded-full ">
                <a href="https://www.facebook.com/digitaxsol">
                  <img src={fb} alt="" />
                </a>
              </div>
              <div className="icons w-[30px] h-[30px] bg-[#0F393E] rounded-full">
                <a href="https://twitter.com/digitaxsol">
                  <img src={twt} alt="" />
                </a>
              </div>
              <div className="icons w-[30px] h-[30px] bg-[#0F393E] rounded-full">
                <a href="https://www.instagram.com/digitaxsol">
                  <img src={ig} alt="" />
                </a>
              </div>
              <div className="icons w-[30px] h-[30px] bg-[#0F393E] rounded-full">
                <a href="https://www.linkedin.com/company/digitaxsol">
                  <img src={lin} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-line pb-[20px]">
          <img src={dash} alt="" />
        </div>
        <div className="bottom-bar w-full flex items-baseline justify-between">
          <div className="bottom-bar-left flex items-center justify-center gap-[5px]">
            <div className="idrak-logo">
              <img src={idrak} alt="" />
            </div>
            <div className="seperator">
              <img src={seperator} alt="" />
            </div>
            <div className="ingenetic-logo">
              <img src={ingenetic} alt="" />
            </div>
          </div>
          <div className="bottom-line-phone pt-[8px]">
            <img src={dash} alt="" />
          </div>
          <div className="bottom-bar-right flex gap-[56px]">
            <div>
              <p>@2023 Copyrights</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showThankyou}
        placement="center"
        className="relative z-[999] flex items-center justify-center left-[37%] top-[34%] max-w-fit w-full h-full mx-auto mt-[10%]"
      >
        <div
          className="form-header flex self-end p-3 cursor-pointer"
          onClick={closeThankyoumodal}
        >
          <img src={closebutton} alt="" />
        </div>

        <div className="flex flex-col items-center justify-center px-24 py-5 gap-5 thankyoumodal">
          <div>
            <img src={checkimage} alt="" />
          </div>
          <div className="flex flex-col items-center justify-center gap-2 thankyoucontext">
            <h1>Thank you!</h1>
            <p>Your submission has been received</p>
          </div>
          <div className="helpline">
            <p>
              Helpline num: <span className="text-[#29B34E]">03327779013</span>
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModal}
        className="modal-main flex relative items-center justify-center z-[9999] w-full"
      >
        <div className="form-wrapper flex flex-col w-[466px] bg-white absolute z-[9999]">
          <div
            className="form-header flex self-end p-3 cursor-pointer"
            onClick={closeModal}
          >
            <img src={closebutton} alt="" />
          </div>
          <div className="form-content px-7">
            <form
              className="flex flex-col gap-4"
              ref={form}
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col gap-2">
                <label htmlFor="Name">Name</label>
                {errors.name && <span className="error">{errors.name}</span>}
                <input
                  placeholder="Abvcd*"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="Email">Contact Email</label>
                {errors.email && <span className="error">{errors.email}</span>}
                <input
                  placeholder="you@example.com"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <label htmlFor="Name">Phone Number</label>
              {errors.country && (
                <span className="error">{errors.country}</span>
              )}
              {errors.phoneNumber && (
                <span className="error">{errors.phoneNumber}</span>
              )}
              <div className="flex items-center gap-[15px]">
                <CountrySelector setData={setFormData} data={formData} />
                <div className="flex flex-col gap-2">
                  <input
                    className="phoneNumber-input w-[324px]"
                    placeholder="XXXXXXXXX*"
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    inputMode="numeric"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="Name">Your Message</label>
                {errors.message && (
                  <span className="error">{errors.message}</span>
                )}
                <input
                  placeholder="Type your message ...."
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="message"
                />
              </div>
              <div className="flex self-center pb-8">
                <button
                  className="form-send w-[107px] h-[31px] bg-[#29B34E] text-white "
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
