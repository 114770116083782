import axios from "axios";

import React, { useState } from "react";
import { MutatingDots, Oval } from "react-loader-spinner";

export default function Deleteaccount() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showResendLink, setShowResendLink] = useState(false);
  const [emailerror, setEmailError] = useState();
  const [otpError, setOTPError] = useState();
  const [loading, setLoading] = useState(false);

  const handleEnterEmail = async (e) => {
    e.preventDefault();
    // Here you can add email validation logic if needed
    // For simplicity, we'll just check if email is not empty
    if (email.trim() !== "") {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://api.digitax.ai/users/requestAccountDelete",
          { email }
        );
        setLoading(false);
        setShowOtp(true);
        setShowResendLink(true); // Show resend link after OTP is sent
        setEmailError(null);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setEmailError(error?.response?.data?.error || "Something went wrong");
      }
    } else {
      // Notify user to enter email
      setEmailError("Email Cannot Be empty");
    }
  };

  const handleEnterOtp = async () => {
    // Here you can add OTP validation logic if needed
    // For simplicity, we'll just check if OTP is not empty
    if (otp.trim() !== "") {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://api.digitax.ai/users/deleteOTPConfirmation",
          { email, otp }
        );

        setLoading(false);
        setShowOtp(false);
        setVerified(true);
        setShowResendLink(false); // Show resend link after OTP is sent
        setOTPError(null);
      } catch (error) {
        setLoading(false);
        setOTPError(error?.response?.data?.error || "Something went wrong");
      }
    } else {
      // Notify user to enter OTP
      setOTPError("Please enter the OTP.");
    }
  };

  const handleResendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.digitax.ai/users/requestAccountDelete",
        { email }
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-16 text-center flex flex-col justify-center items-center">
      <h1 className="text-3xl font-bold text-center mb-8">
        Request Account Deletion
      </h1>

      {!verified && (
        <div className="flex flex-col max-w-sm">
          {showOtp ? (
            <div className="flex flex-col">
              <p className="mb-4">Please enter the OTP sent to your email.</p>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="mb-4 px-4 py-2 border border-gray-300 rounded-lg"
              />
              {otpError && <p className="font-light bg-red-500"> {otpError}</p>}
              {showResendLink && (
                <p
                  className="text-blue-500 font-light cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </p>
              )}
              <button
                type="button"
                onClick={handleEnterOtp}
                className={`p-2 cursor-pointer ${
                  loading
                    ? "navbar-button bg-gray-500 cursor-not-allowed text-gray-400"
                    : "navbar-button"
                }`}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <Oval
                      visible={true}
                      height="20"
                      width="20"
                      color="#cccc"
                      ariaLabel="oval-loading"
                      wrapperStyle={{
                        marginRight: 3,
                      }}
                      wrapperClass=""
                      secondaryColor="#ccc"
                    />{" "}
                    {"  "} Verify OTP
                  </div>
                ) : (
                  "Verify OTP"
                )}
              </button>
            </div>
          ) : (
            <div className="flex flex-col max-w-sm">
              <p className="mb-4">
                Are you sure you want to delete your account? Once deleted, your
                account will be permanently deleted and you will not be able to
                recover it.
              </p>
              <form onSubmit={handleEnterEmail}>
                <div className="flex flex-col justify-start">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mb-4 px-4 py-2 border border-gray-300 rounded-lg max-w-sm"
                  />
                  {emailerror && (
                    <p className="font-light text-red-500 text-xs">
                      {emailerror}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className={`inline-block px-4 py-2 text-white rounded-lg max-w-xs navbar-button${
                    loading
                      ? "bg-gray-500 cursor-not-allowed text-gray-400"
                      : "navbar-button bg-red-500 hover:bg-red-700"
                  }`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex flex-row justify-center items-center">
                      <Oval
                        visible={true}
                        height="20"
                        width="20"
                        color="#cccc"
                        ariaLabel="oval-loading"
                        wrapperStyle={{
                          marginRight: 3,
                        }}
                        wrapperClass=""
                        secondaryColor="#ccc"
                      />{" "}
                      {"  "} Yes, I want to delete my account
                    </div>
                  ) : (
                    "Yes, I want to delete my account"
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      )}
      {verified && (
        <div className="text-green-600 font-bold text-center">
          Your account has been deleted successfully!
        </div>
      )}
    </div>
  );
}
