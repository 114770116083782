import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./Components/Root/root";
import Terms from "./Components/Terms & Privacy/Terms";
import Privacy from "./Components/Terms & Privacy/Privacy";
import Footer from "./Components/Footer/Footer";
import Deleteaccount from "./Pages/Deleteaccount";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/Terms",
          element: <Terms />,
        },
        {
          path: "Privacy",
          element: <Privacy />,
        },
        {
          path: "/requestAccountDeactivation",
          element: <Deleteaccount />,
        },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
      <Footer />
    </>
  );
}

export default App;
