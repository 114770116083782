import React from 'react'
import './terms.css'
const Privacy = () => {
  return (
    <>
      <div className="privacy-banner flex flex-col bg-[#09393F] text-white px-16">
        <div className="privacy-heading">
            <h1>Privacy Policy</h1>
        </div>

        <p><b>Commitment to Privacy</b> <a href="/" className='text-[#29B34E]'>DIGITAX</a> respects the privacy of our users and is committed to protecting it while offering a valuable and personalized service. This Privacy Policy outlines how <a href="/" className='text-[#29B34E]'>DIGITAX</a> processes user data. For questions about your personal information or our practices, please reach out to our Privacy Officer via email at <a href="mailto:info@digitax.ai">info@digitax.ai</a></p>
        <p><b>External Links</b> Our website may include links to external websites that are not covered by this policy. We suggest reviewing the privacy policies of any external sites you visit.</p>
        <p><b>Collection of Information</b> <a href="/" className='text-[#29B34E]'>DIGITAX</a> collects personal information, which we refer to as "Data," provided by you both online and offline.</p>
        <p>Additional information may be gathered from your use of our services or automatically during your visit to our website.</p>
        <p><b>Use and Disclosure of Personal Information</b> The purposes for using your Data include:</p>
        <p>•	Delivering a customized service to our users.</p>
        <p>•	Handling tax registration, filings, inquiries, and conducting market research surveys.</p>
        <p>•	Offering interactive service features, subject to user participation.</p>
        <p>•	Running promotions and providing information about our offerings, with user consent.</p>
        <p>•	Ensuring adherence to our terms and conditions.</p>
        <p>With your consent, we may share your Data with our business partners for services such as data processing, lending, or investments, or as legally required.</p>
        <p>We also aggregate information (without individual identification) to:</p>
        <p>•	Create marketing profiles.</p>
        <p>•	Support strategic development.</p>
        <p>•	Conduct site usage audits.</p>
        <p>We employ technology that captures user actions on our website to address usability issues and enhance user support, as well as for aggregate and statistical reports.</p>
        <p><b>Security Measures</b> <a href="/" className='text-[#29B34E]'>DIGITAX</a> has implemented appropriate measures to protect your Data from unauthorized access, alteration, accidental destruction or loss.</p>
        <p><b>Account Deactivation</b> You may discontinue your <a href="/" className='text-[#29B34E]'>DIGITAX</a> account at any time. Your account information will remain intact until reactivation with your password.</p>
        <p><b>Data Transfer</b> As the Internet operates globally, using it to collect and process personal data involves transmitting data internationally. By using the <a href="/" className='text-[#29B34E]'>DIGITAX</a> site and electronic communication, you consent to such data processing.</p>
        <p><b>User Data Access and Management</b> To modify or update your Data or preferences, contact <a href="mailto:info@digitax.ai">info@digitax.ai</a> or use the “Contact Us” section of our site.</p>
        <p><b>Policy Updates</b> This policy, effective as of November 11, 2023, may be updated to reflect changes in privacy practices related to new legislation, best practices, or website updates.</p>


      </div>
    </>
  )
}

export default Privacy
