import React, { useRef, useState } from 'react'
import './Filer.css'
import filerposter from '../../Images/Filer/filerimage.png'
import reviewimage from '../../Images/Filer/reviewimage.png'
import stars from '../../Images/Filer/stars.svg'
import Slider from 'react-slick'
import CountrySelector from '../Country Selector/CountrySelector'
import { Modal } from 'flowbite-react'
import closebutton from '../../Images/Close.svg'
import checkimage from '../../Images/checkimage.svg'


const Filer = () => {
    const images = [reviewimage, reviewimage, reviewimage, reviewimage, reviewimage]
    const names = ['M. Mujtaba Kamal', 'Majid Sharif', 'Syed Hammad Shah', 'Hassam Ali', 'Ehtisham ul Haq'];
    const roles = ['Project Manager', '', '', '', 'Owner of EQ Technologies'];
    const review = ["Thankyou for your cooperation. I highly appreciate your communication and effective response. Will contact next year too Insha'Allah.", "Alhamdulilah, it's a wonderful and seamless experience with Digitax. In Sha Allah will continue with you for the coming years.", "Thank you so much, Really really amazing service Let me know if i can rate you 5 stars anywhere ", "Definitely, the experience was great as I am a newbie at this. Thank you so much for all your patience and help.", "It was amazing. Thankyou so much for all team and Digitax. Really Appreciated."]
    const [imageIndex, setimageIndex] = useState(0)
    const [index, setIndex] = useState(0)
    const [showModal, setshowModal] = useState(false)
    const [showThankyou, setshowThankyou] = useState(false)

    const settings = {
        dots: true,
        lazyload: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        beforeChange: (current, next) => {
            setIndex(next)
            setimageIndex(next)
        }

    }


    const closeModal = () => {
        setshowModal(false)
    }
    const handleOpen = () => {
        setshowModal(true)
    }

    const closeThankyoumodal = () => {
        setshowThankyou(false)
    }

    const form = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        country: '',
        message: ''
    })

    const [errors, setErrors] = useState({})
    function showError(inputName, message) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: message,
        }));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        showError(name, '')
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            setshowModal(false);
            setshowThankyou(true);
            const config = {
                SecureToken: 'fbc47dfe-248b-433e-bc9c-e2106f9ed0b8',
                To: 'info@digitax.ai',
                From: 'info@digitax.ai',
                Subject: 'SCHEDULE',
                Body: `Name :${formData.name},
                       Email: ${formData.email},
                       Country: ${formData.country.value}
                       Phone: ${formData.phoneNumber}
                       Message: ${formData.message}`
            }
            window.Email.send(config)
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name) {
            errors.name = 'Name is required*'
        } else if (data.name.length < 3 || data.name.length > 25) {
            errors.name = 'Name should be between 3 and 25 characters'
        }

        if (!data.email) {
            errors.email = 'Email is required*'
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid Email Format'
        }

        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone Number is required*'
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Invalid Phone Number format'
        }

        if (!data.country) {
            errors.country = 'Select the Country*'
        }

        if (!data.message) {
            errors.message = 'Please fill out this field*'
        }

        return errors;
    }
    return (
        <>
            <div className="main-wrapper flex flex-col bg-[#0F393E] py-[112px]" id='Filer'>
                <div className="upper-section flex flex-row items-center justify-between pl-[72px] pb-[64px]">
                    <div className="upper-left flex flex-col gap-[36px] w-[50%] ">
                        <h1>Become Filer in just </h1>
                        <h2> <span>3000/</span> PKR.</h2>
                        <p>A nominal investment can shield you from the minefield of tax penalties and missed opportunities. For just 3000/PKR, step into the world of informed, streamlined, and efficient tax filing.</p>
                        <button className='bg-[#29B34E] text-white px-3 py-2 rounded-lg hover:bg-white hover:text-[#0F393E] duration-500 box-shadow-custom-box-shadow ' onClick={handleOpen}>Become a Filer Now!</button>
                    </div>
                    <div className="upper-right">
                        <img src={filerposter} alt="" />
                    </div>
                </div>
                <div className="middle-section flex flex-col items-center justify-center py-[66px] bg-[#29B34E]" id='Reviews'>
                    <h1 className='pb-[12px]'>Unmuted: Straight from Digitax Filers!</h1>
                    <p>4.8 out of 5.00 ratings on 300 reviews.</p>
                </div>
                <div className="lower-section pt-[7%] pb-[7%]">
                    <Slider {...settings}>
                        {images.map((img, idx) => (
                            <div className={idx === imageIndex ? 'slide activeSlide' : 'slide'}>
                                <div className="card py-[39px] px-[24px] ">
                                    <div className="card-image flex flex-col items-center justify-center">
                                        <div className='stars pb-[14px]'>
                                            <img src={stars} alt='' />
                                        </div>
                                        <p className='pb-[40px]'>{review[idx]}</p>
                                        <h1>{names[idx]}</h1>
                                        <p>{roles[idx]}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <Modal
                show={showThankyou}
                placement="center"
                className='relative z-[999] flex items-center justify-center left-[37%] top-[34%] max-w-fit w-full h-full mx-auto mt-[10%]'
            >
                <div className='form-header flex self-end p-3 cursor-pointer' onClick={closeThankyoumodal}>
                    <img src={closebutton} alt="" />
                </div>

                <div className='flex flex-col items-center justify-center px-24 py-5 gap-5 thankyoumodal'>
                    <div>
                        <img src={checkimage} alt="" />
                    </div>
                    <div className='flex flex-col items-center justify-center gap-2 thankyoucontext'>
                        <h1>Thank you!</h1>
                        <p>Your submission has been received</p>
                    </div>
                    <div className='helpline'>
                        <p>Helpline num: <span className='text-[#29B34E]'>03327779013</span></p>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showModal}
                className='modal-main flex relative items-center justify-center z-[9999] w-full'
            >
                <div className="form-wrapper flex flex-col w-[466px] bg-white absolute z-[9999]" >
                    <div className='form-header flex self-end p-3 cursor-pointer' onClick={closeModal}>
                        <img src={closebutton} alt="" />
                    </div>
                    <div className='form-content px-7'>
                        <form className='flex flex-col gap-4' ref={form} onSubmit={handleSubmit}>
                            <div className='flex flex-col gap-2'>

                                <label htmlFor="Name">Name</label>
                                {errors.name && <span className='error'>{errors.name}</span>}
                                <input
                                    placeholder='Abvcd*'
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="Email">Contact Email</label>
                                {errors.email && <span className='error'>{errors.email}</span>}
                                <input
                                    placeholder='you@example.com'
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <label htmlFor="Name">Phone Number</label>
                            {errors.country && <span className='error'>{errors.country}</span>}
                            {errors.phoneNumber && <span className='error'>{errors.phoneNumber}</span>}
                            <div className='flex items-center gap-[15px]'>
                                <CountrySelector setData={setFormData} data={formData} />
                                <div className='flex flex-col gap-2'>
                                    <input
                                        className='phoneNumber-input w-[324px]'
                                        placeholder='XXXXXXXXX*'
                                        type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        inputMode='numeric'
                                        required
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor="Name">Your Message</label>
                                {errors.message && <span className='error'>{errors.message}</span>}
                                <input
                                    placeholder='Type your message ....'
                                    type="text"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    className='message'
                                />
                            </div>
                            <div className="flex self-center pb-8">
                                <button className="form-send w-[107px] h-[31px] bg-[#29B34E] text-white "
                                    onClick={handleSubmit}
                                >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default Filer